<template>
  <div class="wrapper-auth">
    <section class="login">
      <div class="login-register">
        <!-- <div class="login-register_overlay"></div> -->
        <div class="login-card">
          <div class="logo">
            <div class="logo-img">
              <img src="@/assets/img/paw-logo.png" alt="" />
            </div>
            <p class="logo-title logo-title_login">CAT MONEY</p>
          </div>
          <div class="form">
            <div class="form-input">
              <select id="country-select" class="country-select" v-model="area">
                <option value="vietnam">Vietnam</option>
                <option value="singapore">Singapore</option>
                <option value="korea">Korea</option>
                <option value="china">China</option>
              </select>
              <input type="text" v-model="username" placeholder="Tên đăng nhập" @input="validateInput" />
              <input type="password" v-model="pwd" placeholder="Mật khẩu" />
              <input type="text" v-model="invite_code" placeholder="Mã giới thiệu" />
            </div>
            <p class="text-green mt-4 " style="    font-style: italic;">* Tên đăng nhập lớn hơn 6 kí tự!</p>
            <p class="text-green " style="    font-style: italic;">* Không bao gồm kí tự đặc biệt!</p>
            
            <div class="flex justify-center">
              <button class="login-btn" @click="register">Đăng ký</button>
            </div>
          </div>
          <p class="title-register">
            Đã có tài khoản? <span class="text-register"><router-link to="/auth/login">Đăng nhập</router-link></span>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
  <script>
  import network from "@/core/network";
  
  export default {
      data() {
          return {
              username: '',
              pwd: '',
              pwdRe: '',
              isshowpw: false,
              isshowpwre: false,
              invite_code: 'CATA1',
              area: 'vietnam'
          }
      }, 
      methods: {
          validateInput(event) {
      const regex = /^[A-Za-z0-9]+$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^A-Za-z0-9]/g, '');
      }
    },
          register() {
            
            if (!this.username || !this.pwd ) {
              this.$toast.open({
                  message: 'Vui lòng điền đầy đủ thông tin !',
                  type: 'error',
                  position: 'top',
                  duration:3000,
                });
                return false;
            }

            if (this.username.length < 6) {
              this.$toast.open({
                  message: 'Tên đăng nhập lớn hơn 6 kí tự !',
                  type: 'error',
                  position: 'top',
                  duration:3000,
                });
                return false;
            }

            // if(this.invite_code.length > 1) {

            // }

              network.post('/auth/signup', {
                  name: this.username,
                  password: this.pwd,
                  repassword: this.pwdRe,
                  invite_code: this.invite_code,
                  area: this.area
              })
           .then(response => {
                  if (response.data.code == 405) {
                    
                    this.$toast.open({
                message: response.data.message,
                type: 'error',
                position: 'top',
                duration:3000,
              });
                    this.$router.push("/auth/login");
                  } else {
                    if (response.data.success) {
                      localStorage.setItem('access_token', response.data.access_token)
                      this.$toast.open({
                        message: 'Đăng ký tài khoản thành công !',
                        type: 'success',
                        position: 'top',
                        duration:3000,
                      });
                      setTimeout(() => {
                        this.$router.push('/home')
                      }, 1000)
                    }
                  }
              })
           .catch(error => {
                  this.$toast.open({
                        message: error.response.data.message,
                        type: 'error',
                        position: 'top',
                        duration:3000,
                      });              
                  });
          }, 
      }
  };
  </script>
  
  <style>
    input {
      background: white !important
    }
  </style>